<template>
  <div class="branch-list-item">
    <a href="#" @click="$emit('branchSelected', branch)">
      <h1>{{ branch.nazev }}</h1>
      <p>{{ branch.adresa }}</p>
    </a>
  </div>
</template>

<script>
export default {
  name: "BranchListItem",
  props: {
    branch: {
      type: Object,
      required: true,
    },
  },
  emits: ["branchSelected"],
  components: {},
  setup() {},
};
</script>

<style scoped>
.branch-list-item {
  margin: 0;
}

h1 {
  font-size: 1.2rem;
  margin: 0;
}
p {
  margin-top: 0.5em;
  margin-bottom: 0;
  color: var(--grey);
}

a {
  text-decoration: none;
  color: var(--grey);
  display: block;

  padding: 1rem 0.7rem;
  border-bottom: 1px solid #eee;
}
a:hover {
  background-color: #eee;
}

.badge {
  font-size: 0.9rem;
  background-color: var(--grey);
}
</style>
