let initialized = !!window.SMap;
let resolveInitPromise;
let rejectInitPromise;
// This promise handles the initialization status of the Mapy.cz script.
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve;
  rejectInitPromise = reject;
});

export default function init() {
  // If Mapy.cz already is initialized the `initPromise` should be resolved
  if (initialized) {
    resolveInitPromise(window.SMap);
    return initPromise;
  }

  initialized = true;

  const scriptLoaded = () => {
    window.Loader.async = true;
    window.Loader.load(null, { suggest: true, pano: true }, () => {
      resolveInitPromise(window.SMap);
    });
  };

  let smapScript = document.createElement("script");
  smapScript.setAttribute("src", "https://api.mapy.cz/loader.js");
  smapScript.async = true;
  smapScript.onload = scriptLoaded;
  smapScript.onerror = rejectInitPromise;
  document.head.appendChild(smapScript);

  return initPromise;
}
