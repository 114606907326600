<template>
  <slot v-if="scriptsLoaded"></slot>
</template>

<script>
import smapsInit from "@/services/SMapsInitService";
import { ref, provide, onMounted } from "vue";

export default {
  name: "SLoader",
  setup() {
    const scriptsLoaded = ref(false);
    const SMap = ref(null);
    const JAK = ref(null);

    provide("SMap", SMap);
    provide("JAK", JAK);

    onMounted(async () => {
      SMap.value = await smapsInit();
      JAK.value = window.JAK;

      scriptsLoaded.value = true;
    });

    return { scriptsLoaded };
  },
};
</script>

<style scoped></style>
