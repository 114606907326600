<template>
  <div class="flex flex-col max-h-full">
    <div class="p-2">
      <SSuggest
        class="h-10 w-full"
        placeholder="Hledat podle adresy / města"
        @coordinates-updated="updateCoordinates"
      />
    </div>
    <div class="overflow-y-scroll overflow-x-hidden">
      <BranchListItem
        v-for="b in branches"
        :key="b.psc"
        :branch="b"
        @branch-selected="$emit('branchSelected', b)"
      />
    </div>
  </div>
</template>

<script>
// import { ref, computed, provide, onMounted } from "vue";
import SSuggest from "@/components/SMaps/SSuggest";
import BranchListItem from "@/components/BranchListItem";

export default {
  name: "BranchList",
  props: {
    branches: {
      type: [Array],
      required: true,
    },
  },
  components: {
    SSuggest,
    BranchListItem,
  },
  emits: ["coordinatesUpdated", "branchSelected"],
  setup(props, { emit }) {
    const updateCoordinates = (coordinates) => {
      emit("coordinatesUpdated", coordinates);
    };

    return { updateCoordinates };
  },
};
</script>

<style scoped></style>
