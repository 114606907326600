<template>
  <div v-bind="$attrs" ref="panoramaEl"></div>
</template>

<script>
import { ref, inject, onMounted, onBeforeUpdate } from "vue";

const showPanorama = (SMap, panoramaScene, lng, lat, positionTolerance) => {
  // kolem teto pozice chceme nejblizsi panorama
  let position = SMap.Coords.fromWGS84(lng, lat);

  SMap.Pano.getBest(position, positionTolerance).then(
    function(place) {
      panoramaScene.value.show(place);
    },
    function() {
      console.warn(
        `SPano: Pro polohu [${lng}, ${lat}] se nepodařilo načíst Panorama. Můžete zkusit zvětšit 'positionTolerance'`
      );
    }
  );
};

export default {
  name: "SPano",
  props: {
    lngLat: {
      type: [Object, Array],
      default: null,
      required: true,
    },
    positionTolerance: {
      // Tolerance v metrech při hledání nejbližěího Panorama
      type: Number,
      default: 50,
    },
  },

  setup(props) {
    const panoramaEl = ref(null);
    const panoramaScene = ref(null);

    onMounted(() => {
      const SMap = inject("SMap").value;

      let options = {
        nav: false, // skryjeme navigaci
        pitchRange: [0, 0], // zakazeme vertikalni rozhled
      };
      panoramaScene.value = new SMap.Pano.Scene(panoramaEl.value, options);

      showPanorama(
        SMap,
        panoramaScene,
        props.lngLat[0],
        props.lngLat[1],
        props.positionTolerance
      );
    });

    onBeforeUpdate(() => {
      const SMap = inject("SMap").value;

      showPanorama(
        SMap,
        panoramaScene,
        props.lngLat[0],
        props.lngLat[1],
        props.positionTolerance
      );
    });

    return { panoramaEl };
  },
};
</script>

<style scoped></style>
