<template>
  <tr>
    <th>
      {{ day }}
    </th>
    <td>
      <div v-if="openingHours[day].length == 0">-</div>
      <div v-for="h in openingHours[day]" :key="h">{{ h.od }} - {{ h.do }}</div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "BranchDetailOpeningHour",
  props: {
    day: {
      type: String,
      required: true,
    },
    openingHours: {
      type: [Object],
      required: true,
    },
  },
  components: {},

  setup() {},
};
</script>

<style scoped>
th {
  padding-right: 1rem;
  vertical-align: top;
}
</style>
