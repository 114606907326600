<template>
  <div class="branch-detail">
    <div class="branch-detail__header">
      <button class="btn-link" @click="$emit('closeBranchDetail')">
        &#8592; Zpět na výpis
      </button>
    </div>
    <div class="branch-detail__content">
      <div class="branch-detail__pano" v-if="branch">
        <SPano :lngLat="[branch.lng, branch.lat]" />
      </div>
      <h1>{{ branch?.nazev }}</h1>
      <div class="badge">{{ branch.typ }}</div>
      <p>{{ branch?.adresa }}</p>

      <h4>Otevírací doba:</h4>
      <table>
        <BranchDetailOpeningHour
          day="Pondělí"
          :openingHours="branch?.otev_doby"
        />
        <BranchDetailOpeningHour
          day="Úterý"
          :openingHours="branch?.otev_doby"
        />
        <BranchDetailOpeningHour
          day="Středa"
          :openingHours="branch?.otev_doby"
        />
        <BranchDetailOpeningHour
          day="Čtvrtek"
          :openingHours="branch?.otev_doby"
        />
        <BranchDetailOpeningHour
          day="Pátek"
          :openingHours="branch?.otev_doby"
        />
        <BranchDetailOpeningHour
          day="Sobota"
          :openingHours="branch?.otev_doby"
        />
        <BranchDetailOpeningHour
          day="Neděle"
          :openingHours="branch?.otev_doby"
        />
      </table>
    </div>
    <div class="branch-detail__footer">
      <button @click="$emit('finalBranchSelected', branch)">
        Zvolit tuto pobočku
      </button>
    </div>
  </div>
</template>

<script>
import BranchDetailOpeningHour from "@/components/BranchDetailOpeningHour.vue";
import SPano from "@/components/SMaps/SPano";

export default {
  name: "BranchDetail",
  props: {
    branch: {
      type: Object,
      required: true,
    },
  },
  emits: ["closeBranchDetail", "finalBranchSelected"],
  components: {
    BranchDetailOpeningHour,
    SPano,
  },

  setup() {},
};
</script>

<style scoped>
.branch-detail {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  width: 100%;
  height: 100%;
  overflow: auto;

  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-content: stretch;
}

.branch-detail * {
  box-sizing: border-box;
}

.branch-detail__header {
  flex: 0 1 auto;
  padding: 1rem;
}
.branch-detail__content {
  overflow-y: auto;
  flex: 1 1 auto;
  padding: 0 1rem 1rem 1rem;
}
.branch-detail__footer {
  flex: 0 1 auto;
  padding: 0;
  width: 100%;
}

.branch-detail__footer button {
  text-align: center;
  background-color: #20bf55;
  color: #fff;
  font-weight: bold;
  display: block;
  width: 100%;

  padding: 1rem;
  transition: 0.2s ease all;
}
.branch-detail__footer button:hover {
  background-color: #1eae4e;

  padding: 1rem;
}

.branch-detail__pano {
  width: 100%;
  height: 153px;
  margin: 0 0 2rem 0;
}

table {
  text-align: left;
}

.badge {
  text-transform: capitalize;
  background-color: var(--blue-light);
  color: #fff;
  display: inline-block;
  padding: 0.1em 0.5em;
  border-radius: 0.5em;
  font-weight: bolder;
}
</style>
