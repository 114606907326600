<template>
  <input type="text" v-bind="$attrs" ref="inputEl" />
</template>

<script>
import { ref, inject, onMounted, onUnmounted } from "vue";

export default {
  name: "SSuggest",
  emits: ["coordinatesUpdated"],
  setup(props, { emit }) {
    const suggest = ref(null);
    const inputEl = ref(null);

    onMounted(() => {
      const SMap = inject("SMap").value;

      suggest.value = new SMap.Suggest(inputEl.value);

      suggest.value.urlParams({
        // omezeni pro celou CR
        bounds: "48.5370786,12.0921668|51.0746358,18.8927040",
        category:
          "address_cz,area_cz,country_cz,district_cz,municipality_cz,quarter_cz,region_cz,street_cz,ward_cz,traffic_cz,trafficpoi_cz",
      });

      suggest.value.addListener("suggest", (suggestData) => {
        const res = {
          lng: suggestData.data.longitude,
          lat: suggestData.data.latitude,
        };
        emit("coordinatesUpdated", res);
      });

      suggest.value.addListener("enter", (enterData) => {
        new SMap.Geocoder(enterData.phrase, (geocoder) => {
          if (!geocoder.getResults()[0].results.length) {
            alert(`${enterData.phrase} se nepodařilo nalézt.`);
            return;
          }

          const firstResult = geocoder.getResults()[0].results[0];
          const res = {
            lng: firstResult.coords.x,
            lat: firstResult.coords.y,
          };

          emit("coordinatesUpdated", res);
        });
      });
    });

    onUnmounted(() => {
      suggest.value.removeListener("suggest");
      suggest.value.removeListener("enter");
    });
    return { suggest, inputEl };
  },
};
</script>

<style scoped></style>
